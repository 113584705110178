<!--物流配送-->
<template>
    <div class="ReturnGoods2">
        <div v-if="this.language==='zh_CN'">
            <h3>退货政策</h3>
            <p>
                退货是根据 2001 年立陶宛共和国民法典第 6.228(10) 条的欧盟指令进行的。 6 月 29 日根据立陶宛共和国经济部长第
                10 号命令。 第217号《关于批准退换货规则》及WHEIDA内部规则。
            </p>
            <p>要退回物品，请在收到物品/包裹后的 7 个工作日内通过电子邮件 eu-info@foherb.com 通知我们。</p>

            <h4>退回的物品必须满足以下条件：</h4>
            <p>a) 退回的商品完好无损，原包装且处于可销售状态；</p>
            <p>b) 货物没有损坏；</p>
            <p>c) 该物品未被使用（磨损等）。</p>

            <h4>运输途中损坏</h4>
            <p>
                如果货物在运输过程中损坏，在这种情况下我们将赔偿由此造成的损失。 如果您在运输过程中发现交货有任何缺陷，请告知我们。
                在货物交付过程中，有必要检查包裹的状况。 如果您在交付过程中发现违规行为，您有权不接受包裹。
                如果您已接受包裹，则视为包裹已完好无损地送达。
            </p>

            <h4>不可退货</h4>
            <p>使用过的商品不能退货。</p>
            <p>如果您有任何疑问，请通过以下联系方式与我们联系：</p>
            <p>周一至周四 10.00-18.00</p>
            <p>Tel.: +37066137436</p>
            <p>eu-info@foherb.com</p>
        </div>
        <div v-else-if="this.language==='he_IL'">
            <h3>מדיניות ההחזרים</h3>
            <p>
              החזרת מוצר מתבצעת בהתאם להנחיות האיחוד האירופי, סע' (10)6.228 לקודקס האזרחי של רפובליקת ליטא מיום 29 ביוני 2001, צו שר הכלכלה של רפובליקת ליטא מס' 217 "בעניין אישור כללי החזרה והחלפה של מוצרים" וכן נהלים פנימיים של חב' WHEIDA
            </p>
            <p>אם ברצונכם להחזיר מוצר, אנא עדכנו אותנו בדוא"ל eu-info@foherb.com תוך 7 ימי עבודה מרגע קבלת מוצר/חבילה</p>

            <h4>המוצר המוחזר חייב לעמוד בתנאים הבאים:</h4>
            <p>א) המוצר המוחזר נמצא באריזה המקורית שלא ניזוקה</p>
            <p>ב) לא נגרם נזק למוצר</p>
            <p>ג) לא נעשה כל שימוש במוצר</p>

            <h4>נזקי הובלה</h4>
            <p>
              אם המוצר ניזוק במהלך הובלתו, אנו נפצה אתכם גין ההפסד. אם במעמד המסירה הבחנתם בנזקי הובלה כלשהם, אנא עדכנו אותנו. במעמד מסירת המוצר יש לבדוק את מצב החבילה. במידה והבחנתם בנזק, זכותכם שלא לקבל את החבילה. בקבלת החבילה הנכם מאשרים שהחבילה נמסרה ללא נזקים.
            </p>

            <h4>מוצרים שאינם ניתנים להחזרה</h4>
            <p>לא ניתן להחזיר מוצרים שנעשה בהם שימוש</p>
            <p>אם יש לכם שאלות, אנא צרו איתנו קשר בערוצים הבאים: </p>
            <p>I-IV 10.00-18.00</p>
            <p>Tel.: +37066137436</p>
            <p>eu-info@foherb.com</p>
        </div>
        <div v-else-if="this.language==='lt_LT'">
            <h3>Grąžinimo politika</h3>
            <p>Prekių grąžinimas vyksta remiantis ES direktyvomis, Lietuvos Respublikos civilinio kodekso 6.228(10)
                straipsnio 1 d., 2001 m. birželio 29 d. LR Ūkio ministro įsakymu Nr. 217 „Dėl daiktų grąžinimo ir
                keitimo taisyklių patvirtinimo“, bei WHEIDA kompanijos vidaus taisyklėmis.
            </p>
            <p>Norėdami grąžinti prekę, informuokite mus el.paštu eu-info@foherb.com per 7 darbo dienas nuo
                prekės/siuntos gavimo.</p>

            <h4>Grąžinamos prekės turi atitikti šiuos kriterijus:</h4>
            <p>a) lGrąžinama prekė yra nepažeistoje, orginalioje pakuotėje ir išlaikiusi prekinę išvaizdą;</p>
            <p>b) lPrekė yra pilnos komplektacijos, kokią pirkėjas gavo;</p>
            <p>c) lPrekė nebuvo sugadinta;</p>
            <p>d) lPrekė nebuvo naudojama (dėvima ir pan.).</p>

            <h4>Sugadinta transportuojant</h4>
            <p>
                Jei prekė buvo pažeista pervežimo metu, šiuo atveju atlyginsime patirtus nuostolius. Jei pastebėjote
                siuntos trūkumus, atsiradusius pervežimo metu, prašome atitinkamai informuoti mus. Prekių pristatymo
                metu Jūs privalote patikrinti siuntos būklę. Pastebėję siuntos pažeidimą, turite teisę siuntos
                nepriimti. Priėmus siuntą, laikoma, kad siunta buvo pristatyta be pažeidimų.
            </p>

            <h4>Negrąžinamos prekės</h4>
            <p>Panaudotos prekės yra negrąžinamos.</p>
            <p>Iškilus klausimams, maloniai prašome kreiptis į mus žemiau nurodytais kontaktais:</p>
            <p>I-IV 10.00-18.00</p>
            <p>Tel.: +37066137436</p>
            <p>eu-info@foherb.com</p>
        </div>
        <div v-else-if="this.language==='ru_RU'">
            <h3>Политика возврата</h3>
            <p>
                Возврат товара осуществляется на основании директив ЕС, статьи 6.228(10) Гражданского кодекса Литовской Республики от 2001 года. 29 июня Приказом министра экономики Литовской Республики №. № 217 «Об утверждении правил возврата и обмена товаров» и внутренних правил компании WHEIDA.
            </p>
            <p>Чтобы вернуть товар, сообщите нам об этом по электронной почте eu-info@foherb.com в течение 7 рабочих дней с момента получения товара/посылки.</p>

            <h4>Возвращаемый товар должен соответствовать следующим критериям:</h4>
            <p>a) Возвращаемый товар находится в неповрежденной, оригинальной упаковке и сохранил товарный вид;</p>
            <p>b) Товар не был поврежден;</p>
            <p>c) Товар не использовался (носился и т.п.).</p>

            <h4>Повреждение при транспортировке</h4>
            <p>
                Если товар был поврежден при транспортировке, в этом случае мы возместим понесенные убытки. Если вы заметили какие-либо дефекты в доставке во время транспортировки, пожалуйста, сообщите нам об этом. Во время доставки товара необходимо проверить состояние посылки. Вы имеете право не принимать посылку, если заметите нарушение при доставке. Если Вы приняли посылки, считается, что посылка доставлена без повреждений.
            </p>

            <h4>Невозвратные товары</h4>
            <p>Товары, бывшие в употреблении, возврату не подлежат.</p>
            <p>Если у Вас возникли вопросы, пожалуйста, свяжитесь с нами по следующим контактам:</p>
            <p>I-IV 10.00-18.00</p>
            <p>Tel.: +37066137436</p>
            <p>eu-info@foherb.com</p>
        </div>
        <div v-else-if="this.language==='zh_CN'">
        <h3>დაბრუნების პოლიტიკა</h3>
        <p>
          საქონლის დაბრუნება ხორციელდება
          ევროკავშირის დირექტივების საფუძველზე,
          2001 წლის ლიტვის რესპუბლიკის სამოქალაქო
          კოდექსის 6.228(10) მუხლი. 29 ივნისს,
          ლიტვის რესპუბლიკის ეკონომიკის
          მინისტრის ბრძანებით, №29. No217
          „საქონლის დაბრუნებისა და გაცვლის წესების
          დამტკიცების შესახებ“ და WHEIDA-ს შიდა წესები.
        </p>
        <p>საქონლის დასაბრუნებლად გთხოვთ
          შეგვატყობინოთ ელფოსტით
          uabkordicepsas@gmail.com ნივთის/ამანათის
          მიღებიდან 7 სამუშაო დღის განმავლობაში.</p>

        <h4>დასაბრუნებული საქონელი უნდა
          აკმაყოფილებდეს შემდეგ კრიტერიუმებს:</h4>
        <p>ა) დაბრუნებული პროდუქტი არის დაუზიანებელ,
          ორიგინალურ შეფუთვაში და პირვანდელ მდგომარეობაში;</p>
        <p>ბ) საქონელი არ დაზიანდა;</p>
        <p>გ) პროდუქტი არ ყოფილა
          გამოყენებული (ნახმარი და ა.შ.).</p>

        <h4>დაზიანება ტრანსპორტირების დროს</h4>
        <p>
          თუ საქონელი დაზიანდა ტრანსპორტირებისას,
          ამ შემთხვევაში ჩვენ აგინაზღაურებთ მიყენებულ
          ზარალს. თუ ტრანზიტის დროს შეამჩნევთ რაიმე
          ხარვეზს, გთხოვთ შეგვატყობინოთ. საქონლის
          მიტანისას აუცილებელია ამანათის მდგომარეობის
          შემოწმება. თქვენ გაქვთ უფლება არ მიიღოთ ამანათი,
          თუ მოტანის დროს დარღვევას შეამჩნევთ.
          თუ თქვენ მიიღებთ ამანათი, ითვლება,
          რომ ამანათი მიწოდებულია დაზიანების გარეშე.
        </p>

        <h4>საქონელი რომელიც არ დაიბრუნება</h4>
        <p>ნახმარი ნივთების დაბრუნება შეუძლებელია.
        </p>
        <p>თუ თქვენ გაქვთ რაიმე შეკითხვები, გთხოვთ დაგვიკავშირდეთ შემდეგ კონტაქტებზე:</p>
        <p>I-IV 10.00-18.00</p>
        <p>ტელ.: +37060296453</p>
        <p>uabkordicepsas@gmail.com
        </p>
      </div>
        <div v-else>
            <h3>Return Policy</h3>
            <p>
                The return of goods is based on EU directives, Article 6.228(10) of the Civil Code of the Republic of Lithuania, dated 2001. June 29 By order of the Minister of Economy of the Republic of Lithuania no. 217 "On the approval of the rules for returning and exchanging items", as well as the internal rules of the WHEIDA company.
            </p>
            <p>To return an item, please inform us by e-mail at eu-info@foherb.com within 7 working days of receiving the item/package.</p>

            <h4>Returned goods must meet the following criteria:</h4>
            <p>a) The returned item is kept as undamaged, original packaging and has retained its commercial appearance；</p>
            <p>b) The product is complete as the buyer received it；</p>
            <p>c) The product was not damaged；</p>
            <p>d) Item has not been used (worn, etc.)</p>

            <h4>Damage during transportation</h4>
            <p>
                If the product was damaged during transportation, we will compensate for the losses incurred in this case.If you notice any defects during transportation , please inform us immediately. During the delivery of the goods, you must check the condition of the shipment. You have the right not to accept the shipment if you notice a shipment violation. Upon receipt of the shipment, it is considered that the shipment was delivered without damage.
            </p>

            <h4>Non-returnable items</h4>
            <p>Used goods are non-refundable.</p>
            <p>If you have any questions, please contact us as  the following contacts:</p>
            <p>I-IV 10:00-18:00</p>
            <p>Tel.: +37066137436</p>
            <p>eu-info@foherb.com</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: 'ReturnGoods2',
    computed: {
        ...mapGetters([
            'language'
        ]),
    }
}
</script>

<style scoped lang="scss">
.ReturnGoods2 {
  text-align: left;
  max-width: 1200px;
  margin: 0 auto
}
</style>
